







import rService from '../services/RequestService/RequestServiceSetup';

export default {
  methods: {
    confirm() {
      this.$swal
        .fire({
          title: 'Your account will be removed from our database and it will be impossile to restore it.',
          confirmButtonText: 'Okay',
          allowOutsideClick: false,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const res = await rService.post('user/destroy/accept', { token: this.$route.params.id });
            if (res && res.success) {
              this.$swal
                .fire({
                  title: 'Your account was destroyed',
                  confirmButtonText: 'Okay',
                })
                .then(() => {
                  this.$store.dispatch('logout_instant');
                });
            }
          }
        });
    },
  },
};
